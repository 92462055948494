/**
 * @file It contains scripts for report login page.
 */
import ThemisPinInput from "@/components/shared/pin-input"
import { mapActions, mapGetters } from "vuex"

export default {
  name      : "Login",
  components: { ThemisPinInput },
  data() {
    return {
      credentials: {
        username: undefined,
        password: undefined
      },
      showPassword        : false,
      showReportNumberHelp: false
    }
  },
  computed: {
    ...mapGetters({
      isInvalidCredentials: "auth/isInvalidCredentials",
      reporterLanguage    : "auth/reporterLanguage",
      isLoggedIn          : "auth/isLoggedIn",
      isLoggingIn         : "auth/isLoggingIn",
      report              : "auth/report"
    }),
    isMobileView() {
      return !this.$vuetify.breakpoint.mdAndUp
    },
    isUsernameLengthValid() {
      return this.credentials.username?.length === 8
    },
    isPasswordInputDisabled() {
      return !this.isUsernameLengthValid
    },
    isLoggedInButtonDisabled() {
      return !(this.isUsernameLengthValid && this.credentials.password)
    }
  },
  methods: {
    ...mapActions({
      loginUsingCredentials: "auth/loginUsingCredentials",
      notify               : "shared/notify"
    }),
    setFocus() {
      const input = this.$refs.input_report_number
      if (input) {
        input.focus()
      }
    }
  },
  mounted() {
    this.setFocus()
    if (this.$route.query.username) {
      this.credentials.username = atob(this.$route.query.username)
    }
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.$router.push({
            name  : "messages",
            params: {
              locale  : this.reporterLanguage.shortName,
              channel : this.$route.params.channel,
              reportId: this.report.id
            }
          })
        }
      }
    },
    isInvalidCredentials: function(newValue) {
      if (newValue) {
        this.notify({
          type: "error",
          text: "91"
        })
      }
    }
  }
}