import { mapGetters } from "vuex"

export default {
  name: "PhoneNumbers",
  data: () => ({
    showHowDoesItWorkDialog: false
  }),
  computed: {
    ...mapGetters({
      channels                 : "channels/channels",
      phoneNumbers             : "channels/phoneNumbers",
      askOrganisationCodeForWeb: "auth/askOrganisationCodeForWeb"
    }),
    sortedPhoneNumbersByCountry() {
      return this.phoneNumbers.sort(
        (phoneNumber1, phoneNumber2) => phoneNumber1.country.localeCompare(phoneNumber2.country)
      )
    },
    channel() {
      return this.channels.find(channel => channel.name === this.$route.params.channel)
    },
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    },
    showCheckWithYourOrganisationText() {
      return  this.channel.overrideAskOrganisationCodeForWeb
        ? this.channel.askOrganisationCodeForWeb
        : this.askOrganisationCodeForWeb
    }
  }
}